<template>
	<div class="m_common_content">
		<div class="m_common_top">
			<div class="m_checkdata_content">
				设备报警记录
			</div>
		</div>
		<div class="m_common_middle">
			<div style="display: flex;margin-bottom: 10px;">
				<h1 class="h1s">设备状态：
					<p v-if="vminfo.vmOperateStatus=== 'Normal'" style="display: inline-block;">
						<p v-if="vminfo.vmFaultStatus === 'Invalid'" style="display: inline-block;">
							<i style="color: #ff0000">故障(
							<i v-if="vminfo.vmMachineStatus === 'Online'" style="color: #62c462">在线</i>
							<i v-else style="color: #666">离线</i>
							)</i>
						</p>
						<p v-else style="display: inline-block;">
							<i v-if="vminfo.vmMachineStatus === 'Online'" style="color: #62c462">在线</i>
							<i v-else style="color: #666">离线</i>
						</p> 
					</p>
					<p v-if="vminfo.vmOperateStatus=== 'Stopped'" style="display: inline-block;"> 
						<i style="color: #666">停售</i>
					</p>
				</h1>
				<h1 class="h1s"><font color="#19C104">{{vminfo.vmTtemperature}}°C</font></h1>
				<h1 class="h1s">机器管理员:{{userInfo.adminName}}</h1>
				<h1 class="h1s">系统版本号:{{vminfo.appVersion}}</h1>
			</div>
			<el-table :data="[vminfo]">
				<el-table-column prop="goodsName" label="省" show-overflow-tooltip>
				</el-table-column>
				<el-table-column prop="goodsCategory" label="市" show-overflow-tooltip>
				</el-table-column>
				<el-table-column prop="goodsPrice" label="区县" show-overflow-tooltip>
				</el-table-column>
				<el-table-column prop="address" label="详细地址" show-overflow-tooltip>
				</el-table-column>
				<el-table-column prop="positionName" label="点位名称" show-overflow-tooltip>
				</el-table-column>
				<el-table-column prop="vmCode" label="设备编号" show-overflow-tooltip>
				</el-table-column>
				<el-table-column prop="goodsPrice" label="加盟商" show-overflow-tooltip>
				</el-table-column>
				<el-table-column label="运营状态" show-overflow-tooltip>
		            <template slot-scope="scope">
						<i v-if="scope.row.vmOperateStatus=== 'Normal'" style="color: #62c462">正常</i>
						<i v-else style="color: #666">停售</i>
		            </template>
		        </el-table-column>
			</el-table>
			<div style="display: flex;margin: 10px 0px;align-items: center;">
				<el-button @click="beforeDay" size="small" type="primary">前天日志下载</el-button>
				<el-button @click="yesterday"  size="small" type="primary">昨天日志下载</el-button>
				<el-button @click="today"  size="small" type="primary">今天日志下载</el-button>
				<el-button @click="cancel" size="small">返回</el-button>
			</div>
			<div v-show="isDownload">
				日志上传中,请稍后重试......
			</div>
		</div>
	</div>
</template>

<script>
	import {
		machinesInfo,
		machinesFault,
		machinesValid,
		machinesInvalid,
		machineLogs
	} from "@/api/equipment";
	import {
		machinefaultitemsReports,
		machinefaultitemsExport
	} from "@/api/reportForm";
	import { XHR_URL } from "@/utils/config";
	export default {
		data() {
			return {
				code: this.$route.query.code,
				id: this.$route.query.id,
				tableData: [],
				vminfo: {},
				userInfo:{},
				isDownload:false
			}
		},
		created() {
			this.info();
		},
		methods: {
			beforeDay(){
				this.isDownload =true;
				let time = this.$options.filters.dateformat(new Date().getTime()-86400000*2)
				this.download(time);
			},
			yesterday(){
				this.isDownload =true;
				let time = this.$options.filters.dateformat(new Date().getTime()-86400000)
				this.download(time);
			},
			today(){
				this.isDownload =true;
				let time = this.$options.filters.dateformat(new Date())
				this.download(time);
				
			},
			cancel() {
				this.$router.push({
					name:'vmInfo',
					query:{
						id:this.id,
						code:this.code
					}
				});
			},
			async info() {
				const res = await machinesInfo({
					vmCode:this.code,
				});
				const {
					pageCount,
					results
				} = res;
				this.vminfo = results[0];
				this.userInfo = results[0].admin;
			},
			async download(time){
				const res = await machineLogs({
					id:this.id,
					date:time
				});
				const {results }= res;
				console.log(results);
				if(results[0].code==0){
					
					this.isDownload =false;
					location=XHR_URL+results[0].url;
				}
				
			}
		},
		
	}
</script>

<style lang="less" scoped>
	@import "../../assets/css/common/common.less";
	.h1s{
		font-size: 20px;
		font-weight: bold;
		margin: 0px 15px;
	}
	.h1-title{
		font-size: 14px;
		font-weight: bold;
		margin: 15px 0px;
	}
	.vm-states-list p{
		margin: 10px 0px;
	}
</style>
<style lang="less">
	@import "../../assets/css/checkdata/checkdata.less";
</style>